




































































































































































































import { Component, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import Validation from "@/components/Validation.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { IAccountsConfig } from "@/interfaces/IAccountsConfig";
import { Validations } from "vuelidate-property-decorators";
import { minValue, required } from "vuelidate/lib/validators";
import FscCard from "@/components/Card/FscCard.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import { mixins } from "vue-class-component";

const AccountConfigurationModule = namespace("accounts-config");

@Component({
  components: {
    FscPageHeader,
    FscCard,
    SaveButton,
    AbortButton,
    Validation,
    FormCard,
  },
})
export default class Accounting extends mixins(CompanyMixin) {
  public name = "Accounting";

  @AccountConfigurationModule.Action("findOne")
  public findAccountConfigAction: any;

  @AccountConfigurationModule.Action("update")
  public updateAccountConfigAction: any;

  @AccountConfigurationModule.Getter("getDataItem")
  public accountConfigGetter: any;

  @AccountConfigurationModule.Getter("getSuccess")
  public success: any;

  @AccountConfigurationModule.Getter("getIsLoading")
  public isLoading: any;

  public revenues19Percent = 0;
  public cash = 0;
  public taxableWith16Percent = 0;
  public livePay = 0;
  public revenues7Percent = 0;
  public livePayAdditionalCosts = 0;
  public taxableWith5Percent = 0;
  public transitoryItem = 0;
  public revenues0Percent = 0;
  public accountFrame = "";
  public moneyTransit = 0;

  public fibuFixIncomingEmail = "";
  public fibuFixOutgoingEmail = "";

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }
    const data = {
      revenues19Percent: this.revenues19Percent,
      cash: this.cash,
      taxableWith16Percent: this.taxableWith16Percent,
      livePay: this.livePay,
      revenues7Percent: this.revenues7Percent,
      livePayAdditionalCosts: this.livePayAdditionalCosts,
      taxableWith5Percent: this.taxableWith5Percent,
      transitoryItem: this.transitoryItem,
      revenues0Percent: this.revenues0Percent,
      accountFrame: this.accountFrame,
      moneyTransit: this.moneyTransit,
      fibuFixOutgoingEmail: this.fibuFixOutgoingEmail,
      fibuFixIncomingEmail: this.fibuFixIncomingEmail,
    };
    this.updateAccountConfigAction(data);
  }

  public onAbort(): void {
    console.log("Aborted!");
  }

  public mounted(): void {
    this.findAccountConfigAction();
  }

  @Watch("accountConfigGetter")
  public initData(data: IAccountsConfig): void {
    if (data) {
      this.revenues19Percent = data.revenues19Percent;
      this.cash = data.cash;
      this.taxableWith16Percent = data.taxableWith16Percent;
      this.livePay = data.livePay;
      this.revenues7Percent = data.revenues7Percent;
      this.livePayAdditionalCosts = data.livePayAdditionalCosts;
      this.taxableWith5Percent = data.taxableWith5Percent;
      this.transitoryItem = data.transitoryItem;
      this.revenues0Percent = data.revenues0Percent;
      this.accountFrame = data.accountFrame;
      this.moneyTransit = data.moneyTransit;
      this.fibuFixOutgoingEmail = data.fibuFixOutgoingEmail;
      this.fibuFixIncomingEmail = data.fibuFixIncomingEmail;
    }
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      accountFrame: { required },
      revenues19Percent: { required, minValue: minValue(10) },
      cash: { required, minValue: minValue(10) },
      taxableWith16Percent: { required, minValue: minValue(10) },
      livePay: { required, minValue: minValue(10) },
      revenues7Percent: { required, minValue: minValue(10) },
      livePayAdditionalCosts: { required, minValue: minValue(10) },
      taxableWith5Percent: { required, minValue: minValue(10) },
      transitoryItem: { required, minValue: minValue(10) },
      revenues0Percent: { required, minValue: minValue(10) },
      moneyTransit: { required, minValue: minValue(10) },
    };
  }
}
