
















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import PrintButton from "@/components/Button/PrintButton.vue";
import TimePicker from "@/components/TimePicker.vue";
import { downloadFile, getFile } from "@/utils/File";
import { namespace } from "vuex-class";
import { IInstructor } from "@/interfaces/IInstructor";
import DatepickerNoValidation from "@/components/DatepickerNoValidation.vue";
import { formatInstructorName } from "@/utils/NameUtil";

const InstructorModule = namespace("instructor");
const CountryModule = namespace("country");

@Component({
  components: {
    FscPageHeader,
    FscSimpleCard,
    Datepicker,
    Validation,
    TimePicker,
    PrintButton,
    DatepickerNoValidation,
  },
})
export default class PersoFix extends Vue {
  public name = "PersoFix";

  // Instructor
  @CountryModule.Action("filter")
  protected instructorFilter: any;

  @CountryModule.Getter("getDataList")
  protected instructors!: any;

  public instructorId: any = null;
  public year: any = null;
  public month: any = null;
  public startingTime?: Date | null = null;
  public licenseClass = false;
  public drivingLessonType = false;
  public separateWeekends = false;
  public theoryLesson = false;
  public missedDrivingLesson = false;
  public otherAppointments = false;
  public separateGroups = true;
  public splitByDays = false;

  private filesTypesOptions = [
    { text: "CSV", value: "csv" },
    { text: "PDF", value: "pdf" },
  ];

  public fileType = "csv";

  public created() {
    const month = new Date().getMonth() == 0 ? 12 : new Date().getMonth();
    const year = new Date().getMonth() == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();
    this.month = month < 10 ? "0" + month : month + "";
    this.year = year + "";
  }
  public mounted() {
    this.instructorFilter({
      resource: "instructor",
      filter: {
        archived: false,
      },
    });
  }

  protected get instructorOptions(): Array<any> {
    return this.instructors.map((instructor: IInstructor) => {
      return this.instructorFormat(instructor);
    });
  }

  protected instructorFormat(instructor: IInstructor): any {
    if (!instructor) return null;
    return {
      id: instructor.id,
      name: formatInstructorName(instructor.firstName, instructor.lastName),
      initials: instructor.initials,
    };
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      instructorId: { required },
      year: { required },
      month: { required },
    };
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const filter = {
      year: this.year,
      month: this.month,
      instructorId: this.instructorId,
      startingTime: this.startingTime,
      licenseClass: this.licenseClass,
      drivingLessonType: this.drivingLessonType,
      separateWeekends: this.separateWeekends,
      theoryLesson: this.theoryLesson,
      missedDrivingLesson: this.missedDrivingLesson,
      otherAppointments: this.otherAppointments,
      separateGroups: this.separateGroups,
      splitByDays: this.splitByDays,
    };

    if (this.fileType === "csv") {
      downloadFile(
        {
          url: `/reports/instructor-time-sheet/${this.fileType}`,
          method: "post",
          data: filter,
        },
        `export.${this.fileType}`
      );
    } else {
      getFile({
        url: `/reports/instructor-time-sheet/${this.fileType}`,
        method: "post",
        data: filter,
      });
    }
  }
}
