var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('div',{staticClass:"d-flex"},[_c('fsc-page-header',[_vm._v(_vm._s(_vm.$t("sidebar.accounting")))])],1),_c('div',{staticClass:"btn-container mb-3 mr-0"},[_c('abort-button',{attrs:{"label":""},on:{"click":function($event){return _vm.onAbort()}}}),_c('save-button',{staticClass:"ml-2",attrs:{"label":_vm.$t('general.save'),"disabled":_vm.hasNotPermissionWrite,"loading":_vm.isLoading},on:{"click":function($event){return _vm.onSubmit()}}})],1)])]),_c('b-col',{staticClass:"h-100 overflow-hidden",attrs:{"md":"12","lg":"12"}},[_c('FscCard',{staticClass:"h-100"},[_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.account_frame"))+" ")]),_c('Validation',{attrs:{"validations":_vm.$v.accountFrame},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-radio-group',{class:{ 'is-invalid': invalid },attrs:{"id":"radio-slots","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.accountFrame),callback:function ($$v) {_vm.accountFrame=$$v},expression:"accountFrame"}},[_c('b-form-radio',{attrs:{"value":"SKR03"}},[_vm._v(" "+_vm._s(_vm.$t("configuration.skr03"))+" ")]),_c('b-form-radio',{attrs:{"value":"SKR04"}},[_vm._v(" "+_vm._s(_vm.$t("configuration.skr04"))+" ")])],1)}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("configuration.account_define"))+" ")])]),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.revenues_19_percent"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.revenues19Percent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.revenues19Percent),callback:function ($$v) {_vm.revenues19Percent=$$v},expression:"revenues19Percent"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.cash"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.cash},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.cash),callback:function ($$v) {_vm.cash=$$v},expression:"cash"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.taxable_16_percent"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.taxableWith16Percent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.taxableWith16Percent),callback:function ($$v) {_vm.taxableWith16Percent=$$v},expression:"taxableWith16Percent"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.live_pay"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.livePay},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.livePay),callback:function ($$v) {_vm.livePay=$$v},expression:"livePay"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.revenues_7_percent"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.revenues7Percent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.revenues7Percent),callback:function ($$v) {_vm.revenues7Percent=$$v},expression:"revenues7Percent"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.live_pay_additional"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.livePayAdditionalCosts},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.livePayAdditionalCosts),callback:function ($$v) {_vm.livePayAdditionalCosts=$$v},expression:"livePayAdditionalCosts"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.taxable_5_percent"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.taxableWith5Percent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.taxableWith5Percent),callback:function ($$v) {_vm.taxableWith5Percent=$$v},expression:"taxableWith5Percent"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.money_transit"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.moneyTransit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.moneyTransit),callback:function ($$v) {_vm.moneyTransit=$$v},expression:"moneyTransit"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.revenues_0_percent"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.revenues0Percent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.revenues0Percent),callback:function ($$v) {_vm.revenues0Percent=$$v},expression:"revenues0Percent"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12","lg":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.passing_item"))+" * ")]),_c('Validation',{attrs:{"validations":_vm.$v.transitoryItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.transitoryItem),callback:function ($$v) {_vm.transitoryItem=$$v},expression:"transitoryItem"}})}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("configuration.fibu_fix_emails_details_text"))+" ")])]),_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.fibu_fix_outgoing_email"))+" ")]),_c('b-form-input',{attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.fibuFixOutgoingEmail),callback:function ($$v) {_vm.fibuFixOutgoingEmail=$$v},expression:"fibuFixOutgoingEmail"}})],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("configuration.fibu_fix_incoming_email"))+" ")]),_c('b-form-input',{attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.fibuFixIncomingEmail),callback:function ($$v) {_vm.fibuFixIncomingEmail=$$v},expression:"fibuFixIncomingEmail"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }